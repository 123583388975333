(function($) {
    		
	var responsive_current_width = window.innerWidth;
	var responsive_min_width = 992;
	var responsive_mobile = responsive_current_width < responsive_min_width;
	var body_overlay = $('.body_overlay');

	function swapChildren(obj1, obj2)
	{
		var temp = obj2.children().detach();
		obj2.empty().append(obj1.children().detach());
		obj1.append(temp);
	}

	function toggleMobileStyles()
	{
		if (responsive_mobile) {
			$("*[id^='_desktop_']").each(function(idx, el) {
				var target = $('#' + el.id.replace('_desktop_', '_mobile_'));
				if (target.length) {
					swapChildren($(el), target);
				}
			});
			$("body").addClass('mobile');
		} else {
			$("*[id^='_mobile_']").each(function(idx, el) {
				var target = $('#' + el.id.replace('_mobile_', '_desktop_'));
				if (target.length) {
					swapChildren($(el), target);
				}

			});
			$("body").removeClass('mobile');

			if(!$('#mobileCartModal').hasClass('is-active')) {
				removeBodyOverlay();
			 	$('[data-modal-hide-mobile].is-active').removeClass('is-active');	
			} 		
		}
	}

	function addBodyOverlay() {
		$('body').prepend('<div class="body_overlay"></div>');
		$('body').addClass('modal-active');
	}

	function removeBodyOverlay() {
		$('.body_overlay').fadeOut(300, function() { $(this).remove(); });
		$('body').removeClass('modal-active');
	}


	$(window).on('resize', function() {
		var _cw = responsive_current_width;
		var _mw = responsive_min_width;
		var _w = window.innerWidth;
		var _toggle_1 = (_cw >= _mw );
		var _toggle = (_cw >= _mw && _w < _mw) || (_cw < _mw && _w >= _mw);
		responsive_current_width = _w;
		responsive_mobile = responsive_current_width < responsive_min_width;
		if (_toggle) {
			toggleMobileStyles();
		}
	});

	$(document).ready(function() {
		var header_height = $('#header').outerHeight();
        
		if (responsive_mobile) {
			toggleMobileStyles();
		}

		// Modal menu
		$('body').on('click', '.headerHamburger', function(e) {
			e.preventDefault();
			addBodyOverlay();
			var modal = $(this).attr('modal');
			$('body').find('#' + modal).addClass('is-active');
		});

        $('body').on('click', '.modalClose--menu', function(e) {
			e.preventDefault();	
			removeBodyOverlay();
			var modal = $(this).attr('modal');
			$('body').find('#' + modal).removeClass('is-active');
		});

		$('body.home').on('click', 'a', function(event) {

		// })

		// $("a").on('click', function(event) {

			// Make sure this.hash has a value before overriding default behavior
			if (this.hash !== "") {
			  // Prevent default anchor click behavior
			  event.preventDefault();
		
			  // Store hash
			  var hash = this.hash;
		
			  // Using jQuery's animate() method to add smooth page scroll
			  // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
			  $('html, body').animate({
				scrollTop: $(hash).offset().top - (header_height)
			  }, 800, function(){
		
				// Add hash (#) to URL when done scrolling (default click behavior)
				// window.location.hash = hash;
			  });
			} // End if
		  });
	});
		
})(jQuery);