(function($) {
    $(window).on('load', () => {
    //   AOS.init({
    //     offset: jQuery(window).height() * 0.1,
    //   });
    const header = document.querySelector('header');

        window.addEventListener('scroll', function (e) {
            let scrollPosition = window.scrollY;
            if (+scrollPosition > 40) {
                if (!header.classList.contains('header--scrolled')) {
                    header.classList.add('header--scrolled');
                }
            } else {
                header.classList.remove('header--scrolled');
            }
        });
    });

    
    

    $(document).ready(function() {
        /**
         * Disable WPCF7 button while it's submitting
         * Stops duplicate enquiries coming through
         */

        document.addEventListener( 'wpcf7submit', function( event ) {
        
            // find only disbaled submit buttons
            var button = $('.wpcf7-submit[disabled]');
        
            // grab the old value
            var old_value = button.attr('data-value');
        
            // enable the button
            button.prop('disabled', false);
        
            // put the old value back in
            button.text(old_value);
        
        }, false );

        $('form.wpcf7-form').on('submit',function() {

            // console.log('123');

            var form = $(this);
            var button = form.find('.wpcf7-submit');
            var current_val = button.text();
        
            // store the current value so we can reset it later
            button.attr('data-value', current_val);
        
            // disable the button
            button.prop("disabled", true);
        
            // tell the user what's happening
            button.text("Przesyłanie...");
        
        });
    });
})(jQuery);
